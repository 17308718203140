<template>
    <div class="portal-system-theme-addTheme">
        <cgs-modal :visible="styleImportDialog" title="添加主题" @close="$emit('cancelImport')">
            <template #content>
                <div style="text-align:center">
                    <!-- 此处action需为有效的任意接口——当前为官网范例接口 -->
                    <cgs-upload :maxCount="1" action="#" ref="upload" accept=".json" :auto-upload='false'
                        :file-list="fileList" :on-success="onSuccess" @remove="onRemove" :on-exceed="handleExceed"
                        @change="handelImportChange">
                        <!-- <p class="ant-upload-drag-icon">
                            <inboxOutlined></inboxOutlined>
                        </p>
                        <div class="ant-upload-text">将文件拖到此处，或点击上传</div>
                        <div class="ant-upload-hint">
                            上传json文件，且只能上传 1 个文件
                        </div> -->
                        <cgs-button>
                            <template #icon>
                                <span class="cgs-portal-iconfont cgs-portal-icon-a-2D"></span>
                            </template>
                            上传json文件，且只能上传 1 个文件
                        </cgs-button>
                    </cgs-upload>
                </div>
            </template>
            <template #footer>
                <cgs-button type="primary" @click="$emit('cancelImport')">关闭</cgs-button>
                <cgs-button type="primary" @click="importConfirm">确定</cgs-button>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    getCurrentInstance,
    ref
} from 'vue'

export default defineComponent({
    props: {
        styleImportDialog:{
            type: Boolean,
            default: false,
        },
        jsonData:{
            type: Array,
            default:() => []
        }
    },
    setup(props,ctx) {
        let { proxy } = getCurrentInstance();
        const data = ref(props.jsonData)

        const handelImportChange = (file) => {
            let reader = new FileReader();
            reader.readAsText(file.file.originFileObj);
            reader.onload = (e) => {
                data.value = [];
                data.value = JSON.parse(e.target.result);
            };
        }
        const importConfirm = () => {
            proxy.$confirm({
                title: '导入后原数据会被覆盖，确定导入吗?", "温馨提示',
                onOk() {
                    ctx.emit('importOk',data.value)
                },
                onCancel() {
                },
            });
        }
        const onRemove = () => {
            data.value = [];
        }
        return {
            importConfirm,
            onRemove,
            handelImportChange
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";
</style>