<template>
  <cgs-modal class="portal-menu-workspace-add-modal" v-model:visible="vis" title="新建应用" @close="closeModal">
    <template #content>
      <!-- <cgs-form :model="formState" name="basic" ref="sceneForm" :label-col="{ span: 4 }" :wrapper-col="{ span: 20 }"
    labelAlign="left">
    <cgs-form-item :label="$t('modifyModal.spaceTitle')" name="Title"
     :rules="[{ required: true, message: $t('modifyModal.spaceTitleNullError') }]">
     <cgs-input v-model:value="formState.Title" />
    </cgs-form-item>
    <cgs-form-item :label="$t('modifyModal.ThemeIcon')" name="ThemeIcon">
     <div class="portal-menu-workspace-add-modal-theme-icon">
      <div class="portal-menu-workspace-add-modal-theme" :style="{ background: themeColor }"
       :class="[iconFontFamily, showIcon]" @click="openSelectThemeModal"></div>
      <cgs-select-theme-icon v-model:visible='state.themeModelVisible' v-model:iconName="formState.icon"
       :left="state.themeIconLeft" :top="state.themeIconTop + 20" v-model:themeClassName="formState.theme"
       @themeChanged="changeTheme" @iconChanged="changeIcon">
      </cgs-select-theme-icon>
     </div>
    </cgs-form-item>
   </cgs-form> -->
      <div class="portal-menu-workspace-add-modal-content">
        <cgs-button class="portal-menu-workspace-add-modal-btn" :type="select == item.typeId ? 'primary' : ''"
          v-for="item in btnList" :key="item.typeId" @click="createApp(item)">
          <template #icon>
            <span class="portal-menu-workspace-add-modal-icon" :class="item.icon"></span>
          </template>
          <span class="portal-menu-workspace-add-modal-btn-span"> {{ item.typeName }}</span></cgs-button>
      </div>
    </template>
    <template #footer>
      <div class="portal-menu-workspace-add-modal-footer">
        <cgs-button @click="closeModal">{{ $t('common.cancel') }}</cgs-button>
        <cgs-button type="primary" @click="ok">{{ $t('common.ok') }}</cgs-button>
      </div>
    </template>
  </cgs-modal>
  <TwoDAddModal :visible="state.twoDvis" :workSpaceId="workSpaceId" @success="onEditSuccess"
    :selectCategory="state.categoryActiveKey" :categoryOptions="state.categoryList" :sceneType="select"
    :themeOptions="state.themeOptions" :spaceOptions="state.spaceOptions" @close="() => { state.twoDvis = false }">
  </TwoDAddModal>
  <ThreeDAddModal :visible="state.threeDvis" :workSpaceId="workSpaceId" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :selectCategory="state.categoryActiveKey" :categoryOptions="state.categoryList"
    :sceneType="select" @close="() => { state.threeDvis = false }" @success="onEditSuccess"></ThreeDAddModal>
  <EarthModal :visible="state.EarthModal" :workSpaceId="workSpaceId" :sceneType="select"
    :themeOptions="state.themeOptions" :spaceOptions="state.spaceOptions" :selectCategory="state.categoryActiveKey"
    :categoryOptions="state.categoryList" @close="() => { state.EarthModal = false }" @success="onEditSuccess">
  </EarthModal>
  <PageModal :visible="state.PageModal" :workSpaceId="workSpaceId" :sceneType="select"
    :themeOptions="state.themeOptions" :spaceOptions="state.spaceOptions" :selectCategory="state.categoryActiveKey"
    :categoryOptions="state.categoryList" @close="() => { state.PageModal = false }" @success="onEditSuccess">
  </PageModal>
  <IotModal :visible="state.IotModal" :workSpaceId="workSpaceId" :sceneType="select" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :selectCategory="state.categoryActiveKey" :categoryOptions="state.categoryList"
    @close="() => { state.IotModal = false }" @success="onEditSuccess">
  </IotModal>
  <GeoModelModal :visible="state.GeoModelModal" :workSpaceId="workSpaceId" :sceneType="select"
    :selectCategory="state.categoryActiveKey" :categoryOptions="state.categoryList" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" @close="() => { state.GeoModelModal = false }" @success="onEditSuccess">
  </GeoModelModal>
  <VolumeModal :visible="state.VolumeModal" :workSpaceId="workSpaceId" :sceneType="select"
    :themeOptions="state.themeOptions" :spaceOptions="state.spaceOptions" :selectCategory="state.categoryActiveKey"
    :categoryOptions="state.categoryList" @close="() => { state.VolumeModal = false }" @success="onEditSuccess">
  </VolumeModal>
  <CadModal :visible="state.CadModal" :workSpaceId="workSpaceId" :sceneType="select" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :selectCategory="state.categoryActiveKey" :categoryOptions="state.categoryList"
    @close="() => { state.CadModal = false }" @success="onEditSuccess">
  </CadModal>
  <!-- <DataServerModal :visible="state.DataServerModal"></DataServerModal> -->
  <WorkFlowModal :visible="state.WorkFlowModal" :workSpaceId="workSpaceId" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :selectCategory="state.categoryActiveKey" :categoryOptions="state.categoryList"
    :sceneType="select" @close="() => { state.WorkFlowModal = false }" @success="onEditSuccess">
  </WorkFlowModal>
  <DasModal :visible="state.DasModal" :workSpaceId="workSpaceId" :selectCategory="state.categoryActiveKey"
    :categoryOptions="state.categoryList" :sceneType="select" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" @close="() => { state.DasModal = false }" @success="onEditSuccess">
  </DasModal>
  <FileStorageModal :visible="state.FileStorageModal" :workSpaceId="workSpaceId"
    :selectCategory="state.categoryActiveKey" :categoryOptions="state.categoryList" :sceneType="select"
    :themeOptions="state.themeOptions" :spaceOptions="state.spaceOptions"
    @close="() => { state.FileStorageModal = false }" @success="onEditSuccess">
  </FileStorageModal>
  <NewEarthModal :visible="state.NewEarthModal" :workSpaceId="workSpaceId" :selectCategory="state.categoryActiveKey"
    :categoryOptions="state.categoryList" :sceneType="select" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" @close="() => { state.NewEarthModal = false }" @success="onEditSuccess">
  </NewEarthModal>
  <TableModal :visible="state.tableVis" :workSpaceId="workSpaceId" :selectCategory="state.categoryActiveKey"
    :scene-type="select" :categoryOptions="state.categoryList" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :sceneType="select" @success="onEditSuccess"
    @close="() => { state.tableVis = false }">
  </TableModal>
  <ScadaModal :visible="state.ScadaModal" :workSpaceId="workSpaceId" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :selectCategory="state.categoryActiveKey" :scene-type="select"
    :categoryOptions="state.categoryList" :sceneType="select" @success="onEditSuccess"
    @close="() => { state.ScadaModal = false }">
  </ScadaModal>
  <CodeEngineModal :visible="state.CodeEngineModal" :workSpaceId="workSpaceId" :selectCategory="state.categoryActiveKey"
    :scene-type="select" :categoryOptions="state.categoryList" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :sceneType="select" @success="onEditSuccess"
    @close="() => { state.CodeEngineModal = false }">
  </CodeEngineModal>
  <CadDesignModal :visible="state.CadDesignModal" :workSpaceId="workSpaceId" :selectCategory="state.categoryActiveKey"
    :scene-type="select" :categoryOptions="state.categoryList" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :sceneType="select" @success="onEditSuccess"
    @close="() => { state.CadDesignModal = false }">
  </CadDesignModal>
  <ReportModal :visible="state.ReportModal" :workSpaceId="workSpaceId" :selectCategory="state.categoryActiveKey"
    :scene-type="select" :categoryOptions="state.categoryList" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :sceneType="select" @success="onEditSuccess"
    @close="() => { state.ReportModal = false }">
  </ReportModal>
  <LargeScreenModal :visible="state.ScreenModal" :workSpaceId="workSpaceId" :selectCategory="state.categoryActiveKey"
    :scene-type="select" :categoryOptions="state.categoryList" :themeOptions="state.themeOptions"
    :spaceOptions="state.spaceOptions" :sceneType="select" @success="onEditSuccess"
    @close="() => { state.ScreenModal = false }">
  </LargeScreenModal>
</template>

<script setup>
import { defineProps, defineEmits, computed, reactive, onMounted, ref } from 'vue'
import { useRouter } from 'vue-router'
import {
  getSceneTypeSetting,
  getCategory, getThemeList
} from '@api/scene'
import { getWorkSpaceList } from "@/api/workSpace"
// import {
//  getCategory,
//  getList,
//  deleteScene,
//  uploadApp
// } from '@api/scene'
import TwoDAddModal from '@/views/menu/twoD/add-modal.vue'
import ThreeDAddModal from '@/views/menu/threeD/add-modal.vue'
import TableModal from '@/views/menu/table/add-modal.vue'
import EarthModal from '@/views/menu/earth/add-modal.vue'
import WorkFlowModal from '@/views/menu/workflow/add-modal.vue'
import PageModal from '@/views/menu/static/add-modal.vue'
import IotModal from '@/views/menu/iot/add-modal.vue'
import GeoModelModal from '@/views/menu/geoModel/add-modal.vue'
import VolumeModal from '@/views/menu/volume/add-modal.vue'
import CadModal from '@/views/menu/cad/add-modal.vue'
// import DataServerModal from '@/views/menu/dataServer/add-modal.vue'
import DasModal from '@/views/menu/das/add-modal.vue'
import FileStorageModal from '@/views/menu/fileStorage/add-modal.vue'
import NewEarthModal from '@/views/menu/newEarth/add-modal.vue'
import ScadaModal from '@/views/menu/scada/add-modal.vue'
import CodeEngineModal from '@/views/menu/codeEngine/add-modal.vue'
import CadDesignModal from '@/views/menu/cadDesign/add-modal.vue'
import ReportModal from '@/views/menu/report/add-modal.vue'
import LargeScreenModal from '@/views/menu/largeScreen/add-modal.vue'




const props = defineProps({
  vis: {
    type: Boolean,
    default: false
  }
})
const emit = defineEmits(['close', 'ok'])
// const { proxy } = getCurrentInstance()
const router = useRouter()
const vis = computed(() => props.vis)
const state = reactive({
  categoryList: [],
  categoryActiveKey: '',
  twoDvis: false,
  threeDvis: false,
  tableVis: false,
  EarthModal: false,
  WorkFlowModal: false,
  PageModal: false,
  IotModal: false,
  GeoModelModal: false,
  VolumeModal: false,
  CadModal: false,
  DataServerModal: false,
  DasModal: false,
  FileStorageModal: false,
  NewEarthModal: false,
  ScadaModal: false,
  CodeEngineModal: false,
  CadDesignModal: false,
  ReportModal: false,
  ScreenModal: false,
  themeOptions: [],
  spaceOptions: []
})
// let iconFontFamily = font_family
// const sceneForm = ref(null)

const btnList = ref([])
const select = ref("")



const workSpaceId = computed(() => {
  return router.currentRoute.value.params.id
})

onMounted(() => {
  _init()
})
const _init = async () => {
  // try {
  //  let _orgResult = await getCategory('Org')
  //  let _themeResult = await getThemeList()
  //  if (_orgResult.code === 200) {
  //   state.orgOptions = _orgResult.data
  //  }
  //  if (_themeResult.code === 200) {
  //   state.themeOptions = _themeResult.data
  //  }
  // } catch (err) {
  //  console.error(err)
  // }

  let res = await getSceneTypeSetting()
  btnList.value = res.data.filter(item => {
    if (item.typeId === "DataServer" || item.typeId === "IoT") {
      return
    } else {
      return item
    }
  })
  select.value = res.data[0].typeId

  let _themeResult = await getThemeList()
  let _workspaseResult = await getWorkSpaceList()

  if (_themeResult.code === 200) {
    state.themeOptions = _themeResult.data
  }

  if (_workspaseResult.code === 200) {
    state.spaceOptions = _workspaseResult.data
  }


}

// let themeColor = computed({
//  get: () => {
//   let _color = ''
//   let _themeName = formState.theme
//   if (formState.theme === '') _themeName = default_class_name
//   let _themeObj = getThemeObjByClassName(_themeName)
//   _color = _themeObj.color
//   return _color
//  },
//  set: () => { }
// })
// let showIcon = computed({
//  get: () => {
//   let _icon = formState.icon
//   if (formState.icon === '') _icon = defaultIcon_app
//   return profix + _icon
//  },
//  set: () => { }
// })

const close = () => {
  emit('close')
  // props.value = false
}
const closeModal = () => {
  close()
}
const createApp = (item) => {
  select.value = item.typeId

}


const ok = async () => {
  if (select.value === "TwoD") {
    state.twoDvis = true
  } else if (select.value === "App") {
    state.tableVis = true
  } else if (select.value === "ThreeD") {
    state.threeDvis = true
  } else if (select.value === "Earth") {
    state.EarthModal = true
  } else if (select.value === "Workflow") {
    state.WorkFlowModal = true
  } else if (select.value === "Static") {
    state.PageModal = true
  } else if (select.value === "IoT") {
    state.IotModal = true
  } else if (select.value === "GeoModel") {
    state.GeoModelModal = true
  } else if (select.value === "CAD") {
    state.CadModal = true
  } else if (select.value === "Vtk") {
    state.VolumeModal = true
  } else if (select.value === "DAS") {
    state.DasModal = true
  } else if (select.value === "FileStorage") {
    state.FileStorageModal = true
  } else if (select.value === "NewEarth") {
    state.NewEarthModal = true
  } else if (select.value === "SCADA") {
    state.ScadaModal = true
  } else if (select.value === "CodeEngine") {
    state.CodeEngineModal = true
  } else if (select.value === "CadDesign") {
    state.CadDesignModal = true
  } else if (select.value === "Report") {
    state.ReportModal = true
  } else if (select.value === "LargeScreen") {
    state.ScreenModal = true
  }
  try {
    let _categoryResult = await getCategory(select.value)
    if (_categoryResult.code === 200) {
      state.categoryList = _categoryResult.data
      state.categoryList.sort(function (a, b) {
        return Number(a.categoryValue) - Number(b.categoryValue)
      });
      _setCategoryActiveKey()
    }
  } catch (err) {
    console.error(err)
  }
  close()
}
select.value = "TwoD"
const _setCategoryActiveKey = (key) => {
  if (key === undefined || key === null) {
    if (state.categoryList.length > 0) {
      state.categoryActiveKey = state.categoryList[0].categoryValue
    }
  } else {
    state.categoryActiveKey = key
  }
}

const onEditSuccess = () => {
  emit("success")
}

</script>

<style scoped lang="less">
.portal-menu-workspace-add-modal-footer {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.portal-menu-workspace-add-modal-theme {
  width: 32px;
  height: 32px;
  border-radius: 16px;
  text-align: center;
  line-height: 32px;
  color: #FFFFFF;
  cursor: pointer;
}

.portal-menu-workspace-add-modal-content {
  display: flex;
  flex-wrap: wrap;
}

.portal-menu-workspace-add-modal-btn {
  width: 22%;
  margin: 5px 5px;
  display: flex;
  align-items: center;
}

.portal-menu-workspace-add-modal-btn-span {
  text-align: left;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

.portal-menu-workspace-add-modal-icon {
  margin-right: 4px;
}
</style>