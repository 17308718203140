<!--
*@description 
*@author 杜超峰
*@date 2023/04/28 10:10:49
!-->

<template>
  <div class="portal-menu-authority-role-role">
    <div class="portal-menu-authority-role-role-wrapper">
      <div class="portal-menu-authority-role-role-header">
        <role-card :title="$t('auth.role.title')" :desc="$t('auth.role.desc')"
          icon="cgs-portal-iconfont cgs-portal-icon-jiaoseguanli" @click="addRoleRole">
        </role-card>
      </div>
      <div class="portal-menu-authority-role-role-content">
        <cgs-table :columns="showColumns" :data-source="showRoleList" :pagination="false" mode="portal" size="small"
          :backgroundTransparent="true">
          <template #bodyCell="{ column, record, index }">
            <template v-if="column.key === 'index'">
              <div class="portal-menu-authority-role-role-checkbox">
                <cgs-checkbox :checked="state.checkedList.includes(record.id)" @change="onChangeCheckedList(record.id)">
                </cgs-checkbox>
                <span class="portal-menu-authority-role-role-index">{{ index + 1 }}</span>
              </div>
            </template>
            <template v-if="column.key === 'userNum'">
              <span>{{ countUserAndDepartment(record) }}</span>
            </template>
            <template v-if="column.key === 'operation'">
              <cgs-more :menu-list="moreMenuList" @clikMenuItem="clikMenuItemHandler(record, $event)">
              </cgs-more>
            </template>
          </template>
        </cgs-table>
      </div>
      <div class="portal-menu-authority-role-role-footer">
        <cgs-pagination v-model:current="state.pagination.current" v-model:pageSize="state.pagination.pageSize"
          show-quick-jumper show-size-changer :total="state.pagination.total" @change="onChangePagination" />
      </div>
    </div>
    <select-user-modal v-model:visible="state.selectUserModalConfig.visible" :default-selected-list="currentUserIdList"
      @selected="onSelectedUser"></select-user-modal>
    <select-department-modal v-model:visible="state.selectDepartmentModalConfig.visible"
      :default-selected-list="currentDepartmentIdList" @selected="onSelectedDepartment"></select-department-modal>
    <app-role-authority-modal v-if="sceneType === 'App' && state.roleAuthorityModalConfig.visible"
      v-model:visible="state.roleAuthorityModalConfig.visible" :mode="state.roleAuthorityModalConfig.mode"
      :roleId="state.roleAuthorityModalConfig.roleId" @addSuccess="onRoleAddSuccess" @editSuccess="onRoleEditSuccess">
    </app-role-authority-modal>
    <file-role-authority-modal v-else-if="sceneType === 'FileStorage' && state.roleAuthorityModalConfig.visible"
      v-model:visible="state.roleAuthorityModalConfig.visible" :mode="state.roleAuthorityModalConfig.mode"
      :roleId="state.roleAuthorityModalConfig.roleId" @addSuccess="onRoleAddSuccess" @editSuccess="onRoleEditSuccess">
    </file-role-authority-modal>
    <scene-role-authority-modal v-else-if="state.roleAuthorityModalConfig.visible"
      v-model:visible="state.roleAuthorityModalConfig.visible" :mode="state.roleAuthorityModalConfig.mode"
      :roleId="state.roleAuthorityModalConfig.roleId" @addSuccess="onRoleAddSuccess" @editSuccess="onRoleEditSuccess">
    </scene-role-authority-modal>
    <role-user-modal v-model:visible="state.roleUserModalConfig.visible" :userList="currentUserList"></role-user-modal>
  </div>
</template>

<script>
import {
  computed,
  defineComponent,
  getCurrentInstance,
  onMounted,
  reactive,
  watch
} from 'vue'
import {
  useI18n
} from 'vue-i18n'
import {
  useRouter
} from 'vue-router'
import roleCard from './role-card.vue'
import selectUserModal from '@/components/select-user-modal.vue'
import selectDepartmentModal from '@/components/select-department-modal.vue'
import roleUserModal from './role-user-modal.vue'
import {
  updateRoleUser,
  deleteRole
} from '@api/scene'
import appRoleAuthorityModal from './app-role-authority-modal.vue'
import sceneRoleAuthorityModal from './scene-role-authority-modal.vue'
import fileRoleAuthorityModal from './file-role-authority-model.vue'

const columns = [{
  title: '角色名称',
  dataIndex: 'roleName',
  key: 'roleName',
},
{
  title: '描述',
  dataIndex: 'desc',
  key: 'desc',
},
{
  title: '人员数量',
  dataIndex: 'userNum',
  key: 'userNum',
},
{
  title: '',
  dataIndex: 'operation',
  key: 'operation',
  width: '100px',
  fixed: 'right'
}
]
const moreMenu = [{
  title: '',
  key: 'edit',
  icon: 'cgs-portal-iconfont cgs-portal-icon-xiugai'
},
{
  title: '',
  key: 'roleUsers',
  icon: 'cgs-portal-iconfont cgs-portal-icon-yulan'
},
{
  title: '',
  key: 'addUser',
  icon: 'cgs-portal-iconfont cgs-portal-icon-tianjia'
},
{
  title: '',
  key: 'addDepartment',
  icon: 'cgs-portal-iconfont cgs-portal-icon-tianjia'
},
{
  title: '',
  key: 'delete',
  icon: 'cgs-portal-iconfont cgs-portal-icon-shanchu'
}
]
export default defineComponent({
  components: {
    roleCard,
    selectUserModal,
    selectDepartmentModal,
    appRoleAuthorityModal,
    sceneRoleAuthorityModal,
    fileRoleAuthorityModal,
    roleUserModal
  },
  props: {
    roleList: {
      type: Array,
      default: () => []
    }
  },

  setup (props, ctx) {
    const { t } = useI18n()
    const {
      proxy
    } = getCurrentInstance()
    const router = useRouter();
    const showColumns = computed(() => {
      return columns.map(_item => {
        _item.title = t('auth.roleTalbe.' + _item.key)
        return _item
      })
    })
    const moreMenuList = computed(() => {
      return moreMenu.map(_item => {
        _item.title = t('auth.moreMenuList.' + _item.key)
        return _item
      })
    })
    const state = reactive({
      roleConfig: null,
      selectUserModalConfig: {
        visible: false
      },
      selectDepartmentModalConfig: {
        visible: false
      },
      checkedList: [],
      pagination: {
        current: 1,
        pageSize: 20,
        total: -1
      },
      roleAuthorityModalConfig: {
        visible: false,
        mode: 'add',
        roleId: undefined
      },
      roleUserModalConfig: {
        visible: false
      }
    })

    onMounted(() => {
      _init()
    })

    watch(() => props.roleList, () => {
      _init()
    })
    let sceneType = computed(() => {
      return router.currentRoute.value.params.type
    })
    const roleId = computed(() => {
      return state.roleConfig.id
    })
    const ownerUserId = computed(() => {
      let _id = ''
      if (state.roleConfig) {
        let _userList = state.roleConfig.users;
        if (!_userList) {
          _userList = []
        }
        let _user = _userList.find(_user => _user.isOwner)
        if (_user) {
          _id = _user.userId
        }

      }
      return _id
    })
    const currentUserIdList = computed(() => {
      let _list = []
      if (state.roleConfig) {
        let _userList = state.roleConfig.users;
        if (!_userList) {
          _userList = []
        }
        let _userIdList = _userList.map(_user => {
          return _user.userId
        })
        _list = _userIdList
      }
      return _list
    })
    const currentDepartmentIdList = computed(() => {
      let _list = []
      if (state.roleConfig) {
        let _departmentList = state.roleConfig.departments;
        if (!_departmentList) {
          _departmentList = []
        }
        let _departmentIdList = _departmentList.map(_department => {
          return _department.id
        })
        _list = _departmentIdList
      }

      return _list
    })
    watch(() => props.roleList, () => {
      state.pagination.total = props.roleList.length
    })
    const showRoleList = computed(() => {
      let _pagination = state.pagination
      return props.roleList.filter((item, index) => {
        return index >= (_pagination.pageSize * (_pagination.current - 1)) && index < _pagination
          .pageSize * _pagination.current
      })
    })
    const currentUserList = computed(() => {
      let _list = []
      if (state.roleConfig && state.roleConfig.users) {
        let _userList = state.roleConfig.users;
        let _departmentList = state.roleConfig.departments;
        let _userData = _userList.map(_user => {
          return {
            id: _user.userId,
            name: _user.nickName,
            department: '',
            type: 'user',
            addTime: ''
          }
        })
        let _departmentData = _departmentList.map(_department => {
          return {
            id: _department.id,
            name: _department.name,
            department: '',
            type: 'department',
            addTime: ''
          }
        })
        _list = [..._userData, ..._departmentData]
      }

      return _list
    })
    const _init = () => { }
    const openSelectUserModal = () => {
      state.selectUserModalConfig.visible = true
    }
    const openSelectDepartmentModal = () => {
      state.selectDepartmentModalConfig.visible = true
    }
    const openRoleUserModal = () => {
      state.roleUserModalConfig.visible = true
    }
    const onSelectedUser = async (userList) => {
      try {
        let _userIdList = userList.map(_user => _user.userId)
        let params = {
          departmentIds: currentDepartmentIdList.value,
          id: roleId.value,
          userIds: _userIdList
        }
        await _updateRoleUserAndDepartment(params)
        state.roleConfig.users = userList.map(_user => {
          return {
            avatar: "",
            isOwner: _user.userId === ownerUserId.value,
            nickName: _user.name,
            userId: _user.userId
          }
        })
        let _allRoleList = JSON.parse(JSON.stringify(props.roleList))
        ctx.emit('update:roleList', _allRoleList)
        proxy.$message.success(t('auth.authInfo.renewSuccess'))
      } catch (err) {
        console.error(err)
        proxy.$message.error(t('auth.authInfo.renewFail'))
      }
    }
    const onSelectedDepartment = async (departmentList) => {
      try {
        let _departmentIdList = departmentList.map(_user => _user.id)
        let params = {
          departmentIds: _departmentIdList,
          id: roleId.value,
          userIds: currentUserIdList.value
        }
        await _updateRoleUserAndDepartment(params)
        state.roleConfig.departments = departmentList.map(_department => {
          return {
            adminId: _department.adminId,
            adminName: _department.adminName,
            ancestors: _department.ancestors,
            id: _department.id,
            name: _department.name,
            parentId: _department.parentId
          }
        })
        let _allRoleList = JSON.parse(JSON.stringify(props.roleList))
        ctx.emit('update:roleList', _allRoleList)
        proxy.$message.success(t('auth.authInfo.renewSuccess'))
      } catch (err) {
        console.error(err)
        proxy.$message.error(t('auth.authInfo.renewFail'))
      }
    }
    const _updateRoleUserAndDepartment = (params) => {
      return new Promise((resolve, reject) => {
        try {
          updateRoleUser(params).then(result => {
            if (result.code === 200) {
              resolve(true)
            } else {
              reject(false)
            }
          })
        } catch (err) {
          console.error(err)
          reject(false)
        }
      })
    }
    const onChangeCheckedList = (id) => {
      let _index = state.checkedList.findIndex(_item => _item === id)
      if (_index < 0) {
        state.checkedList.push(id)
      } else {
        state.checkedList.splice(_index, 1)
      }
    }
    const onChangePagination = () => { }
    const addRoleRole = () => {
      state.roleAuthorityModalConfig.mode = 'add'
      state.roleAuthorityModalConfig.roleId = undefined
      state.roleAuthorityModalConfig.visible = true
    }
    const updateRoleRole = (role) => {
      state.roleAuthorityModalConfig.mode = 'update'
      state.roleAuthorityModalConfig.roleId = role.id
      state.roleAuthorityModalConfig.visible = true
    }
    const onRoleAddSuccess = (params) => {
      let _role = JSON.parse(JSON.stringify(params))
      _role.roleAlias = 'OtherRole'
      _role.departmentIds = []
      _role.departments = []
      _role.userIds = []
      _role.users = []
      let _allRoleList = JSON.parse(JSON.stringify(props.roleList))
      _allRoleList.unshift(_role)
      ctx.emit('update:roleList', _allRoleList)
    }
    const onRoleEditSuccess = (params) => {
      let _allRoleList = JSON.parse(JSON.stringify(props.roleList))
      let current = _allRoleList.find(_item => _item.id === params.id)
      if (current) {
        current.roleName = params.roleName
        ctx.emit('update:roleList', _allRoleList)
      }
    }
    const countUserAndDepartment = (item) => {
      let _departmentNum = item.departments ? item.departments.length : 0
      let _userNum = item.users ? item.users.length : 0
      return _departmentNum + _userNum
    }
    const onSelectRole = (role) => {
      state.roleConfig = role
    }
    const onDeleteRole = async (role) => {
      try {
        let _result = await deleteRole(role.id)
        if (_result.code === 200) {
          let _allRoleList = JSON.parse(JSON.stringify(props.roleList))
          let _index = _allRoleList.findIndex(_item => _item.id === role.id)
          if (_index > -1) {
            _allRoleList.splice(_index, 1)
            ctx.emit('update:roleList', _allRoleList)
          }
          proxy.$message.success(t('common.deleteSuccess'))
        } else {
          proxy.$message.error(t('common.deleteFail'))
        }
      } catch (err) {
        console.error(err)
        proxy.$message.error(t('common.deleteFail'))
      }
    }
    const clikMenuItemHandler = (record, menuItem) => {
      state.roleConfig = record
      if (menuItem.key === 'edit') {
        updateRoleRole(record)
      } else if (menuItem.key === 'delete') {
        onDeleteRole(record)
      } else if (menuItem.key === 'addUser') {
        openSelectUserModal()
      } else if (menuItem.key === 'addDepartment') {
        openSelectDepartmentModal()
      } else if (menuItem.key === 'roleUsers') {
        openRoleUserModal()
      }
    }
    return {
      showColumns,
      moreMenuList,
      state,
      showRoleList,
      columns,
      currentUserIdList,
      currentDepartmentIdList,
      sceneType,
      currentUserList,
      clikMenuItemHandler,
      openSelectUserModal,
      openSelectDepartmentModal,
      onSelectedUser,
      onSelectedDepartment,
      onChangeCheckedList,
      onChangePagination,
      addRoleRole,
      updateRoleRole,
      onRoleAddSuccess,
      onRoleEditSuccess,
      countUserAndDepartment,
      onSelectRole,
      onDeleteRole
    }
  },
})
</script>

<style lang='less' scoped>
@import '~@style/less/theme.less';

.portal-menu-authority-role-role {
  width: 100%;
  height: 100%;
}

.portal-menu-authority-role-role-wrapper {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.portal-menu-authority-role-role-header {
  // height: 120px;
  display: flex;
  align-items: center;
  flex-wrap: wrap;
}

.portal-menu-authority-role-role-content {
  flex: 1;
  height: 0;
  overflow-y: auto;
}

.portal-menu-authority-role-role-button-text {
  margin-left: 4px;
}

.portal-menu-authority-role-role-operation {
  display: flex;
  flex-direction: row-reverse;
  justify-content: space-between;
}

.portal-menu-authority-role-role-index {
  margin-left: 8px;
}

.portal-menu-authority-role-role-footer {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-top: 1px solid @border-color;
  margin-top: 4px;
}
</style>