<template>
    <div class="portal-system-user-batch">
        <cgs-modal :visible="batchAddDialogVisible" :title="$t('systemInfo.userInfo.batchAddUsers')" @close="$emit('cancelBatchAddModal')">
            <template #content>
                <div class="portal-system-user-batch-wrapper">
                    <div class="portal-system-user-batch-download">
                        <div class="portal-system-user-batch-download-left">
                            <span class="cgs-portal-iconfont cgs-portal-icon-XLS portal-system-user-batch-icon-XLS"></span>
                            <span>{{ $t('systemInfo.userInfo.downloadTmeplateRemind') }}</span>
                        </div>
                        <div class="portal-system-user-batch-download-right" @click="downloadTemplate">
                            {{ $t('systemInfo.userInfo.downloadTemplate') }}
                        </div>
                    </div>
                    <div class="portal-system-user-batch-upload">
                        <cgs-upload name="file" v-model:file-list="fileList" :multiple="true" action="/api/upload-file" @change="handleUploadSuccess">
                            <div class="portal-system-user-batch-upload-wrapper">
                                <div class="portal-system-user-batch-upload-icon">
                                    <span class="cgs-portal-iconfont cgs-portal-icon-shangchuan portal-system-user-batch-icon-shangchuan"></span>
                                </div>
                                <div>
                                    <span class="portal-system-user-batch-upload-left">{{ $t('systemInfo.userInfo.downloadRemind') }}</span><span class="portal-system-user-batch-upload-right">{{ $t('systemInfo.userInfo.clickUpload') }}</span>
                                </div>
                            </div>
                        </cgs-upload>
                    </div>
                </div>
            </template>
            <template #footer>
                <cgs-button type="primary" @click="$emit('cancelBatchAddModal')">{{ $t('common.close') }}</cgs-button>
            </template>
        </cgs-modal>
    </div>
</template>

<script>
import {
    defineComponent,
    ref,
} from 'vue'
import {
        exportTemplate,
    } from '@/api/tenant'

export default defineComponent({
    props: {
        batchAddDialogVisible:{
            type: Boolean,
            default: false,
        }
    },
    setup(props,ctx) {
        const fileList = ref([])
        const downloadTemplate = () => {
            exportTemplate().then((res) => {
                let link = document.createElement("a");
                let blogw = new Blob([res])
                let objectUrl = window.URL.createObjectURL(blogw); //创建一个新的url对象
                link.href = objectUrl;
                let file_name = 'template.xlsx' //文件命名，可以随便取
                link.download = file_name; //  下载的时候自定义的文件名
                link.click();
                window.URL.revokeObjectURL(objectUrl);
            })
        }
        const handleUploadSuccess = (file) => {
            ctx.emit('handleUpload',file)
        }
        return {
            fileList,
            downloadTemplate,
            handleUploadSuccess,
        }
    },
})
</script>

<style lang='less' scoped>
@import "~@style/less/theme.less";

.portal-system-user-batch-download{
    display: flex;
    align-items: center;
    background: @selected-color;
    justify-content: space-between;
    line-height: 32px;
    padding: 0px 10px;
    border-radius: 5px;
    margin-bottom: 10px;
}
.portal-system-user-batch-download-left{
    display: flex;
    align-items: center;
    color: @font-color-3;
}

.portal-system-user-batch-download-right{
    color: @primary-color;
    .text-2;
    cursor:pointer;
}
.portal-system-user-batch-upload-icon{
    margin-bottom: 10px;
}
.portal-system-user-batch-icon-shangchuan{
    font-size: 30px;
    color: @font-color-3;
}
.portal-system-user-batch-upload{
    text-align: center;
    background-color: @selected-color;
    border-radius: 5px;
    padding: 10px;
}
.portal-system-user-batch-upload-wrapper{
    cursor: pointer;
}
.portal-system-user-batch-upload-left{
    color: @font-color-3;
}
.portal-system-user-batch-upload-right{
    color: @primary-color;
}
.portal-system-user-batch-icon-XLS{
    color: @primary-color;
    margin-right: 10px;
}
</style>