<template>
  <div class="portal-home-work">
    <div class="portal-home-work-setting">
      <cgs-popover placement="bottomRight" trigger="click" overlayClassName="tooltipColor">
        <template #content>
          <HomeSetting :setting="homeSetting" @changeModule="changeModule" />
        </template>
        <cgs-tooltip placement="topRight">
          <template #title>
            自定义首页设置
          </template>
          <span class="portal-home-work-setting-icon cgs-portal-iconfont cgs-portal-icon-shouyeshezhimian"></span>
        </cgs-tooltip>
      </cgs-popover>
    </div>
    <MyFlow v-if="homeSetting.myProcess"></MyFlow>
    <div class="portal-home-work-collect">
      <MyCollect v-if="homeSetting.myCollect" :sceneList="collectList" @clikSceneMenuItem="clikSceneMenuItem"
        @enterScene="enterScene" @clikSceneCollect=clikSceneCollect @sortCollect="sortCollect"></MyCollect>
    </div>
    <div class="portal-home-work-collect">
      <RecentUse v-if="homeSetting.myRecent" :sceneList="latelyList" @clikSceneMenuItem="clikSceneMenuItem"
        @enterScene="enterScene" @clikSceneCollect=clikSceneCollect @changeRecent="changeRecent"></RecentUse>
    </div>
    <div class="portal-home-work-collect">
      <MyType v-if="homeSetting.myApp || homeSetting.myWorkspace" :sceneList="appList"
        @clikSceneMenuItem="clikSceneMenuItem" @enterScene="enterScene" @clikSceneCollect=clikSceneCollect
        @onChangeCategory="onChangeCategory" :setting="homeSetting" @updateApp="updateApp"></MyType>
    </div>
    <div class="portal-home-work-collect">
      <AllSpace v-if="homeSetting.allWorkspace" :sceneList="allSpaceList" @clikSceneMenuItem="clikSceneMenuItem"
        @enterScene="enterScene" @clikSceneCollect=clikSceneCollect></AllSpace>
    </div>
    <EditModal v-model:visible="editModal.visible" :scene="editModal.scene" @success="initLeft"
      @close="() => { editModal.visible = false }">
    </EditModal>
    <EditTwoDAddModal v-model:visible="edit.twoDvis.visible" :scene="edit.twoDvis.scene"
      :category-options="edit.categoryList" :scene-type="edit.twoDvis.sceneType" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" @close="() => { edit.twoDvis.visible = false }" @success="initView" />
    <EditThreeDAddModal v-model:visible="edit.threeDvis.visible" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" :scene="edit.threeDvis.scene" :category-options="edit.categoryList"
      :scene-type="edit.threeDvis.sceneType" @close="() => { edit.threeDvis.visible = false }" @success="initView" />
    <EditTableModal v-model:visible="edit.tableVis.visible" :scene="edit.tableVis.scene"
      :category-options="edit.categoryList" :scene-type="edit.tableVis.sceneType" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" @close="() => { edit.tableVis.visible = false }" @success="initView" />
    <EditEarthModal v-model:visible="edit.EarthModal.visible" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" :scene="edit.EarthModal.scene" :category-options="edit.categoryList"
      :scene-type="edit.EarthModal.sceneType" @close="() => { edit.EarthModal.visible = false }" @success="initView" />
    <EditWorkFlowModal v-model:visible="edit.WorkFlowModal.visible" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" :scene="edit.WorkFlowModal.scene" :category-options="edit.categoryList"
      :scene-type="edit.WorkFlowModal.sceneType" @close="() => { edit.workSpaceId.visible = false }"
      @success="initView" />
    <EditPageModal v-model:visible="edit.PageModal.visible" :scene="edit.PageModal.scene"
      :category-options="edit.categoryList" :scene-type="edit.PageModal.sceneType" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" @close="() => { edit.PageModal.visible = false }" @success="initView" />
    <EditIotModal v-model:visible="edit.IotModal.visible" :scene="edit.IotModal.scene"
      :category-options="edit.categoryList" :scene-type="edit.IotModal.sceneType" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" @close="() => { edit.IotModal.visible = false }" @success="initView" />
    <EditGeoModelModal v-model:visible="edit.GeoModelModal.visible" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" :scene="edit.GeoModelModal.scene" :category-options="edit.categoryList"
      :scene-type="edit.GeoModelModal.sceneType" @close="() => { edit.twoDvis.GeoModelModal = false }"
      @success="initView" />
    <EditVolumeModal v-model:visible="edit.VolumeModal.visible" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" :scene="edit.VolumeModal.scene" :category-options="edit.categoryList"
      :scene-type="edit.VolumeModal.sceneType" @close="() => { edit.VolumeModal.visible = false }"
      @success="initView" />
    <EditCadModal v-model:visible="edit.CadModal.visible" :scene="edit.CadModal.scene"
      :category-options="edit.categoryList" :scene-type="edit.CadModal.sceneType" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" @close="() => { edit.CadModal.visible = false }" @success="initView" />
    <EditDasModal v-model:visible="edit.DasModal.visible" :scene="edit.DasModal.scene"
      :category-options="edit.categoryList" :scene-type="edit.DasModal.sceneType" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" @close="() => { edit.DasModal.visible = false }" @success="initView" />
    <EditFileStorageModal v-model:visible="edit.FileStorageModal.visible" :scene="edit.FileStorageModal.scene"
      :category-options="edit.categoryList" :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
      :scene-type="edit.FileStorageModal.sceneType" @close="() => { edit.FileStorageModal.visible = false }"
      @success="initView" />
    <EditNewEarthModal v-model:visible="edit.NewEarthModal.visible" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" :scene="edit.NewEarthModal.scene" :category-options="edit.categoryList"
      :scene-type="edit.NewEarthModal.sceneType" @close="() => { edit.NewEarthModal.visible = false }"
      @success="initView" />
    <EditScadaModal v-model:visible="edit.ScadaModal.visible" :themeOptions="edit.themeOptions"
      :spaceOptions="edit.spaceOptions" :scene="edit.ScadaModal.scene" :category-options="edit.categoryList"
      :scene-type="edit.ScadaModal.sceneType" @close="() => { edit.ScadaModal.visible = false }" @success="initView" />
    <EditCodeEngineModal v-model:visible="edit.CodeEngineModal.visible" :scene="edit.CodeEngineModal.scene"
      :category-options="edit.categoryList" :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
      :scene-type="edit.CodeEngineModal.sceneType" @close="() => { edit.CodeEngineModal.visible = false }"
      @success="initView" />
    <EditCadDesignModal v-model:visible="edit.CadDesignModal.visible" :scene="edit.CadDesignModal.scene"
      :category-options="edit.categoryList" :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
      :scene-type="edit.CadDesignModal.sceneType" @close="() => { edit.CadDesignModal.visible = false }"
      @success="initView" />
    <EditReportModal v-model:visible="edit.ReportModal.visible" :scene="edit.ReportModal.scene"
      :category-options="edit.categoryList" :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
      :scene-type="edit.ReportModal.sceneType" @close="() => { edit.ReportModal.visible = false }"
      @success="initView" />
    <EditLargeScreenModal v-model:visible="edit.LargeScreenModal.visible" :scene="edit.LargeScreenModal.scene"
      :category-options="edit.categoryList" :themeOptions="edit.themeOptions" :spaceOptions="edit.spaceOptions"
      :scene-type="edit.LargeScreenModal.sceneType" @close="() => { edit.LargeScreenModal.visible = false }"
      @success="initView" />


    <DeriveModal v-model:visible="edit.deriveModalConfig.visible"
      @close="() => { edit.deriveModalConfig.visible = false }" :scene="edit.deriveModalConfig.scene">
    </DeriveModal>
    <copy-path-modal v-model:visible="edit.copyPathModalConfig.visible" :scene="edit.copyPathModalConfig.scene"
      :scene-type="edit.copyPathModalConfig.appType" @close="closeCopyPathModal">
    </copy-path-modal>
    <!-- <MyFlow v-model:visible="myflow.visible"></MyFlow> -->
  </div>
</template>

<script setup>
import { ref, getCurrentInstance, reactive, onMounted, inject, watch } from "vue"
// import AppCard from "./component/appCard.vue"
// import AppIcon from "../workSpace/component/appIcon.vue"

import { getList, getThemeList, getSceneDetail, deleteScene, getCategory, getSceneTypeSetting } from "@/api/scene"
import { addFavorite, getWorkSpaceList, getCollectList, deleteWorkSpace, addRecentUsed, getRecentUsedList, getWorkbenchConfig } from "@api/workSpace"


import MyFlow from "./component/myflow.vue"
import MyCollect from "./component/mycollect.vue"
import RecentUse from "./component/recentUse.vue"
import MyType from "./component/mytype.vue"
import AllSpace from "./component/allspace.vue"
import HomeSetting from "./component/homeSetting.vue"


import EditModal from "../workSpace/component/editModal.vue"
import EditTwoDAddModal from '@/views/menu/twoD/edit-modal.vue'
import EditThreeDAddModal from '@/views/menu/threeD/edit-modal.vue'
import EditTableModal from '@/views/menu/table/edit-modal.vue'
import EditEarthModal from '@/views/menu/earth/edit-modal.vue'
import EditWorkFlowModal from '@/views/menu/workflow/edit-modal.vue'
import EditPageModal from '@/views/menu/static/edit-modal.vue'
import EditIotModal from '@/views/menu/iot/edit-modal.vue'
import EditGeoModelModal from '@/views/menu/geoModel/edit-modal.vue'
import EditVolumeModal from '@/views/menu/volume/edit-modal.vue'
import EditCadModal from '@/views/menu/cad/edit-modal.vue'
// import DataServerModal from '@/views/menu/dataServer/edit-modal.vue'
import EditDasModal from '@/views/menu/das/edit-modal.vue'
import EditFileStorageModal from '@/views/menu/fileStorage/edit-modal.vue'
import EditNewEarthModal from '@/views/menu/newEarth/edit-modal.vue'

import EditScadaModal from '@/views/menu/scada/edit-modal.vue'
import EditCodeEngineModal from '@/views/menu/codeEngine/edit-modal.vue'
import EditCadDesignModal from '@/views/menu/cadDesign/edit-modal.vue'
import EditReportModal from '@/views/menu/report/edit-modal.vue'
import EditLargeScreenModal from '@/views/menu/largeScreen/edit-modal.vue'
import DeriveModal from "@/views/menu/table/derive-modal.vue"
import copyPathModal from "@/views/menu/static/copy-path-modal.vue"

// import MyFlow from "./myflow/index.vue"
import { setOriginPath } from "@/utils/author"
import { useRouter } from 'vue-router'
const { proxy } = getCurrentInstance()
const router = useRouter()
const workSpacList = inject('workSpacList')
const collects = inject('collectList')
const editModal = ref({})
// const myflow = reactive({
//   visible: false
// })

// const settingVisible = ref(false)
const homeSetting = reactive({
  allWorkspace: false, // 我的流程
  myCollect: false,
  myRecent: false,
  myApp: false,
  myWorkspace: false,
  myProcess: false
})

const appList = ref([])
const allSpaceList = ref([])
const collectList = ref([])
let types = reactive({
  data: []
})
const edit = reactive({
  categoryList: [],
  categoryActiveKey: '',
  deriveModalConfig: {},
  twoDvis: {},
  threeDvis: {},
  tableVis: {},
  EarthModal: {},
  WorkFlowModal: {},
  PageModal: {},
  IotModal: {},
  GeoModelModal: {},
  VolumeModal: {},
  CadModal: {},
  DataServerModal: {},
  DasModal: {},
  FileStorageModal: {},
  NewEarthModal: {},
  copyPathModalConfig: {},
  ScadaModal: {},
  CodeEngineModal: {},
  CadDesignModal: {},
  ReportModal: {},
  LargeScreenModal: {},
  themeOptions: [],
  spaceOptions: []

})
const latelyList = ref([])


const changeModule = (key, bool) => {
  homeSetting[key] = bool
}

watch(() => collects.value, async () => {
  console.log('collects', collects.value);

  initRecentUse()
  initCollect()
  initMySpace()
  initSetting()
}, { deep: true })

const initSetting = async () => {
  const setting = await getWorkbenchConfig()
  if (setting.code === 200) {
    homeSetting.allWorkspace = setting.data.allWorkspace, // 我的流程
      homeSetting.myCollect = setting.data.myCollect,
      homeSetting.myRecent = setting.data.myRecent,
      homeSetting.myApp = setting.data.myApp,
      homeSetting.myWorkspace = setting.data.myWorkspace,
      homeSetting.myProcess = setting.data = setting.data.myProcess
  } else {
    homeSetting.value = {
      allWorkspace: true, // 我的流程
      myCollect: true,
      myRecent: true,
      myApp: true,
      myWorkspace: true,
      myProcess: true
    }

  }
}

const initRecentUse = async (time = 7) => {
  let recent = await getRecentUsedList({ day: time })
  recent.data = recent.data ? recent.data : []
  recent.data.forEach(item => {
    item.title = item.name ? item.name : item.title
    types.data.forEach(_item => {
      if (item.appType === _item.typeId) {
        item.typeName = _item.typeName
      }
    })
  })
  latelyList.value = recent.data
}
const initCollect = async () => {
  let collect = await getCollectList()
  collect.data = collect.data ? collect.data : []
  collect.data.forEach(item => {
    item.title = item.name ? item.name : item.title
    types.data.forEach(_item => {
      if (item.appType === _item.typeId) {
        item.typeName = _item.typeName
      }
    })
  })
  collectList.value = collect.data
}
const initMySpace = async () => {
  let res = await getWorkSpaceList({ isMyApp: true })
  res.data = res.data ? res.data : []
  res.data.forEach(item => {
    item.title = item.name
  })
  appList.value = res.data
}

const updateApp = (type) => {
  if (type === "App") {
    initMyApp()
  } else {
    initMySpace()
  }
}

onMounted(async () => {
  initCollect()
  initMySpace()
  initSetting()
  initRecentUse()
  let appTypes = await getSceneTypeSetting()
  types.data = appTypes.data ? appTypes.data : []
  let allSpace = await getWorkSpaceList()
  allSpace.data = allSpace.data ? allSpace.data : []
  allSpace.data.forEach(item => {
    item.title = item.name
  })
  allSpaceList.value = allSpace.data
  let _themeResult = await getThemeList()

  if (_themeResult.code === 200) {
    edit.themeOptions = _themeResult.data
  }

  if (allSpace.code === 200) {
    edit.spaceOptions = allSpace.data
  }
})

const initMyApp = async () => {
  let list = await getList({ isMyApp: true })
  list.data = list.data ? list.data : []
  list.data.records.forEach((item) => {
    types.data.forEach(_item => {
      if (item.appType === _item.typeId) {
        item.typeName = _item.typeName
      }
    })
  })
  appList.value = list.data.records
}

const onChangeCategory = async (val) => {
  if (val == 0) {

    initMyApp()
  } else if (val == -1) {
    let res = await getWorkSpaceList({ isMyApp: true })
    res.data = res.data ? res.data : []
    res.data.forEach(item => {
      item.title = item.name
    })
    appList.value = res.data
  }

}


const changeRecent = (key) => {
  initRecentUse(key)
}

const enterScene = async (scene, type = 'edit') => { //差异化
  await addRecentUsed({
    id: scene.id
  })

  if (scene.appType) {
    let url = ""
    if (scene.appType === "TwoD") {
      url = `/2d/viewer.html?ProjId=${scene.projectID}&mode=${type}`
      window.open(url, '_blank');
    } else if (scene.appType === "App") {
      setOriginPath(router.currentRoute.value.path)
      url = '/table/' + scene.id
      window.open(url, '_self');
    } else if (scene.appType === "ThreeD") {
      url = `/3d/viewer.html?ProjId=${scene.projectID}&mode=${type}`
      window.open(url, '_blank');
    } else if (scene.appType === "Earth") {
      url = `/earth/viewer.html?ProjId=${scene.projectID}&mode=${type}`

      window.open(url, '_blank');
    } else if (scene.appType === "Workflow") {
      setOriginPath(router.currentRoute.value.path)
      url = '/algo-workflow/' + scene.id
      window.open(url, '_self');
    } else if (scene.appType === "Static") {
      let _result = await getSceneDetail({
        ID: scene.id,
        VisualType: "Static"
      })
      if (_result.code === 200) {
        let config = _result.data.config;
        url = `/file/${config.sceneUrl}`
        window.open(url, '_blank');
      }
    } else if (scene.appType === "IoT") {
      let url = '/iot/' + scene.id
      window.open(url, '_blank');
    } else if (scene.appType === "GeoModel") {
      setOriginPath(router.currentRoute.value.path)
      url = `/geo-model/main/${scene.projectID}`;
      window.open(url, '_self');
    } else if (scene.appType === "CAD") {
      url = `/cgscad/viewer.html?ProjId=${scene.projectID}&mode=${type}`;
      window.open(url, '_blank');
    } else if (scene.appType === "Vtk") {
      url = `/volume/viewer.html?ProjId=${scene.projectID}&mode=${type}`;
      window.open(url, '_blank');
    } else if (scene.appType === "DAS") {
      url = '/das/' + scene.id
      window.open(url, '_blank');
    } else if (scene.appType === "FileStorage") {
      setOriginPath(router.currentRoute.value.path)
      url = '/file-storage/home/' + scene.id
      window.open(url, '_self');
    } else if (scene.appType === "NewEarth") {
      setOriginPath(router.currentRoute.value.path)
      url = `/earthplus/viewer/${scene.id}/${type}`
      window.open(url, '_blank');
    } else if (scene.appType === "SCADA") {
      if (type == "edit") {
        let url = `/scada/?id=${scene.sceneID}`
        window.open(url, '_blank');

      } else {
        let url = `/scada/preview?id=${scene.sceneID}&r=${new Date().getTime()}`
        window.open(url, '_blank');
      }

    } else if (scene.appType === "CodeEngine") {
      let url = `/code_engine/proc/${scene.id}/`
      window.open(url, '_blank');
    } else if (scene.appType === "CadDesign") {
      if (type == "edit") {
        let url = `/cgsCadDesign/?appId=${scene.id}`
        window.open(url, '_blank');
      } else {
        let url = `/cgsCadDesign/viewer.html?ProjId=${scene.projectID}&mode=preview`;
        window.open(url, '_blank');
      }
    } else if (scene.appType === "Report") {
      let url = '/cgsReport/' + scene.id
      window.open(url, '_self');
    } else if (scene.appType === "LargeScreen") {
      setOriginPath(router.currentRoute.value.path)
      url = `/cgsLargeScreen/viewer/${scene.id}/${type}`
      window.open(url, '_blank');
    }

  } else {
    router.push({
      path: `/home/workSpace/${scene.id}`
    })
  }
}
const sceneControls = async (scene) => {
  if (scene.appType === "TwoD") {
    edit.twoDvis = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "App") {
    edit.tableVis = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "ThreeD") {
    edit.threeDvis = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "Earth") {
    edit.EarthModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "Workflow") {
    edit.WorkFlowModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "Static") {
    edit.PageModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "IoT") {
    edit.IotModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "GeoModel") {
    edit.GeoModelModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "CAD") {
    edit.CadModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "Vtk") {
    edit.VolumeModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "DAS") {
    edit.DasModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "FileStorage") {
    edit.FileStorageModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "NewEarth") {
    edit.NewEarthModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "SCADA") {
    edit.ScadaModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }

  } else if (scene.appType === "CodeEngine") {
    edit.CodeEngineModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "CadDesign") {
    edit.CadDesignModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "Report") {
    edit.ReportModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  } else if (scene.appType === "LargeScreen") {
    edit.LargeScreenModal = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
      workSpaceId: scene.workspaceId || ""
    }
  }
  try {

    let _categoryResult = await getCategory(scene.appType)
    if (_categoryResult.code === 200) {
      edit.categoryList = _categoryResult.data
      _setCategoryActiveKey()
    }
  } catch (err) {
    console.error(err)
  }
}

const sceneDerive = (scene) => {
  if (scene.appType === "App") {
    edit.deriveModalConfig = {
      visible: true,
      scene: scene,
    }
  } else {
    proxy.$confirm({
      title: '提示',
      content: '确定导出?',
      onOk () {
        let url =
          `/api/Cgs/Portal/Export?visualId=${scene.id}&visualType=${scene.appType}`;
        window.open(url);
      },
      onCancel () { },
    });
  }
}
const _setCategoryActiveKey = (key) => {
  if (key === undefined || key === null) {
    if (edit.categoryList.length > 0) {
      edit.categoryActiveKey = edit.categoryList[0].categoryValue
    }
  } else {
    edit.categoryActiveKey = key
  }
}

const clikSceneMenuItem = (scene, menuItemParams) => {
  if (menuItemParams.key === 'edit') {
    //     edit.editModalConfig= {
    //         scene: scene,
    //         visible: true
    //     }
    if (scene.appType) {
      sceneControls(scene)
    } else {
      editModal.value = {
        scene: scene,
        visible: true
      }
    }

  } else if (menuItemParams.key === 'export') { //差异化
    // proxy.$confirm({
    //     title: '提示',
    //     content: '确定导出?',
    //     onOk () {
    //         let url = "/api/app/export?id=";
    //         window.open(url + scene.id);
    //     },
    //     onCancel () { },
    // });
    // edit.deriveModalConfig = {
    //       visible: true,
    //       scene: scene
    // }
    sceneDerive(scene)
  } else if (menuItemParams.key === 'delete') {
    if (scene.appType) {
      deleteSceneById(scene)
    } else {
      delectSpace(scene)
    }

  } else if (menuItemParams.key === 'auth') {
    if (scene.appType) {
      router.push({
        path: `/home/authority/${scene.appType}/${scene.id}`
      })
    } else {
      router.push({
        path: `/home/spaceAuthority/${scene.id}`
      })

    }

  } else if (menuItemParams.key === "preview") {
    enterScene(scene, 'preview')
  } else if (menuItemParams.key === "path") {
    // enterScene(scene, 'edit')
    edit.copyPathModalConfig = {
      scene: scene,
      visible: true,
      sceneType: scene.appType,
    }
  }
}
const deleteSceneById = (scene) => {
  proxy.$confirm({
    title: '提示',
    content: '是否确认永久删除?',
    async onOk () {
      try {
        let params = {
          ID: scene.id,
          VisualType: scene.appType
        }
        let _result = await deleteScene(params)
        if (_result.code === 200) {
          onChangeCategory(0)
          proxy.$message.success('删除成功')
        }
      } catch (err) {
        console.error(err)
      }
    },
    onCancel () { },
  });
}
const delectSpace = (scene) => {
  proxy.$confirm({
    title: '提示',
    content: '是否确认永久删除?',
    async onOk () {
      try {
        let res = await deleteWorkSpace(scene.id)
        if (res.code === 200) {
          proxy.$message.success('删除成功')
          workSpacList("delete")
        }
      } catch (err) {
        console.error(err)
      }
    },
    onCancel () { },
  });
}
const closeCopyPathModal = () => {
  edit.copyPathModalConfig.visible = false
}
const sortCollect = () => {
  workSpacList()
}

const initLeft = (active = -1) => {
  workSpacList()
  onChangeCategory(active)
  initRecentUse()
}
const initView = () => {
  onChangeCategory(-1)
  initRecentUse()
}

const clikSceneCollect = async (scene) => {
  const collect = await addFavorite({
    isCollect: !scene.isCollect,
    id: scene.id,
  })
  if (scene.isCollect) {
    // 取消收藏
    if (collect.code === 200) {
      proxy.$message.success('取消收藏成功')
      scene.isCollect = false
    } else {
      proxy.$message.error('取消收藏失败')
      scene.isCollect = true
    }

  } else {
    // 收藏
    if (collect.code === 200) {
      proxy.$message.success('收藏成功')
      scene.isCollect = true
    } else {
      proxy.$message.error('收藏失败')
      scene.isCollect = false
    }
  }
  let active = scene.appType ? 0 : -1
  initLeft(active)
  initCollect()
}
</script>

<style scoped lang="less">
@import "~@style/less/theme.less";

.portal-home-work {
  position: relative;
  width: 100%;
  height: 100%;
  padding: 25px;

  .portal-home-work-flow-box {
    width: 100%;
    height: 105px;
    background: @background-color;
    .border();
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .portal-home-work-flow-state {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-left: 25px;
    width: 55%;
  }

  .portal-home-work-flow-window {
    margin-right: 21px;
  }

  .portal-home-work-flow-state-item {
    width: 20%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    color: @font-color-1;


    .portal-home-work-icon {
      font-size: 40px;
      color: @primary-color;
    }

    .portal-home-work-flow-state-item-icon {
      margin-right: 20px;
    }
  }
}

.portal-home-work-collect {
  margin-top: 20px;

  .portal-home-work-collect-app {
    display: flex;
    flex-wrap: wrap;
    height: 100%;
    overflow: hidden;
    min-height: 30px;


  }
}

.portal-home-work-flow-title {
  font-size: 16px;
  color: @font-color-1;
  margin-bottom: 10px;
}

.portal-home-work-collect-app-roll {
  width: 100%;
  min-height: 30px;
}

.portal-home-work-collect-app-more {
  color: @primary-color;
  cursor: pointer;
  margin-left: 5px;

}

.portal-home-work-setting {
  position: absolute;
  right: 25px;
  top: 23px;
}

.portal-home-work-setting-icon {
  font-size: 20px;
  color: @font-color-1;
}
</style>

<style lang="less">
@import "~@style/less/theme.less";

.tooltipColor {

  .ant-popover-inner {
    background-color: @card-color !important;
    .border();
  }

  .ant-popover-arrow-content {
    background-color: @card-color !important;
  }

}
</style>
